
import React, { useState, useEffect, useRef } from 'react';
import Button from "../../components/Button/button";


import Logo from "../../assects/logo/Logo.png";
import wallet from "../../assects/images/Wallet.png";
import WalletBlue from "../../assects/svgs/walletsvg.svg";
import globe from "../../assects/images/globe.png";
import flg from "../../assects/svgs/fg (13).svg"
import fg1 from "../../assects/svgs/fg (1).svg"
import fg2 from "../../assects/svgs/fg (2).svg"
import fg3 from "../../assects/svgs/fg (3).svg"
import fg4 from "../../assects/svgs/fg (4).svg"
import fg5 from "../../assects/svgs/fg (5).svg"
import fg6 from "../../assects/svgs/fg (6).svg"
import fg7 from "../../assects/svgs/fg (7).svg"
import fg8 from "../../assects/svgs/fg (8).svg"
import fg9 from "../../assects/svgs/fg (9).svg"
import fg10 from "../../assects/svgs/fg (10).svg"
import fg11 from "../../assects/svgs/fg (11).svg"
import fg12 from "../../assects/svgs/fg (12).svg"
import fg13 from "../../assects/svgs/fg (13).svg"
import fg14 from "../../assects/svgs/fg (14).svg"

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import HoverDropDn from '../../components/HoverDropDn';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import MyWallet from './MyWallet';



const Navbar = ({ handelClick }) => {


    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    const [distance, setDistance] = useState(0)

    let countDown = new Date('Apr 30, 2024 14:00:00 UTC').getTime(),
        x = setInterval(function () {

            let now = new Date().getTime(),
                distance = countDown - now;
            if (distance > 0)
                setDistance(distance)
        }, second)

    const [open, setOpen] = useState(false);
    const [currentChain, setCurrentChain] = useState('Ethereum');

    const [lang, setLang] = useState("Eng");
    const [selectedLang, setSelectedLang] = useState({ img: fg13, title: "English" });
    const [langStatus, setLangStatus] = useState(false);

    const [navBarOpen, setNavBarOpen] = useState(false);
    const { t } = useTranslation();

    const locales = {
        en: { img: fg13, title: "English" },
        gr: { img: fg12, title: "German" },
        fr: { img: fg11, title: "French" },
        es: { img: fg10, title: "Spanish" },
        it: { img: fg9, title: "Italian" },
        du: { img: fg8, title: "Dutch" },
        tu: { img: fg7, title: "Turkey" },
        jp: { img: fg6, title: "Japan" },
        kr: { img: fg5, title: "Korean" },
        nr: { img: fg4, title: "Norwegian" },
        ru: { img: fg3, title: "Russian" },
        ch: { img: fg2, title: "Chinese" },
        pr: { img: fg1, title: "Portugese" },
        vt: { img: fg14, title: "Vietnamese" },

    };

    const handleLangDropdown = () => {
        setLangStatus(!langStatus);
    }
    const handleNavbarMenu = () => {
        setNavBarOpen(!navBarOpen)
    }
    const handleNavbarMenuClose = () => {
        setNavBarOpen(false)
    }

    const dropdownRef = useRef(null);
    const handleLanguageChange = (locale) => {
        i18n.changeLanguage(locale);
        setSelectedLang(locales[locale]);
        setLangStatus(false);

    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setLangStatus(false);
            // Add any other logic to close dropdowns here
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [url, setURL] = useState("en");
    useEffect(() => {

        if (window.location.href !== "") {
            if (window.location.href.slice(-2) === 'en') {
                setURL("en")
                setLang("English")
                i18n.changeLanguage("en");
                setSelectedLang(locales["en"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'gr') {
                setLang("German")
                setURL("gr")
                i18n.changeLanguage("gr");
                setSelectedLang(locales["gr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'fr') {
                setLang("French")
                setURL("fr")
                i18n.changeLanguage("fr");
                setSelectedLang(locales["fr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'es') {
                setLang("Spanish")
                setURL("es")
                i18n.changeLanguage("es");
                setSelectedLang(locales["es"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'it') {
                setLang("Italian")
                setURL("it")
                i18n.changeLanguage("it");
                setSelectedLang(locales["it"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'jp') {
                setLang("Japan")
                setURL("jp")
                i18n.changeLanguage("jp");
                setSelectedLang(locales["jp"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'du') {
                setLang("Dutch")
                i18n.changeLanguage("du");
                setSelectedLang(locales["du"]);
                setURL("du")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'tu') {
                setLang("Turkish")
                i18n.changeLanguage("tu");
                setSelectedLang(locales["tu"]);
                setURL("tu")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'kr') {
                setLang("Korean")
                setURL("kr")
                i18n.changeLanguage("kr");
                setSelectedLang(locales["kr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nr') {
                setLang("Norwegian")
                i18n.changeLanguage("nr");
                setSelectedLang(locales["nr"]);
                setURL("nr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ru') {
                setLang("Russian")
                i18n.changeLanguage("ru");
                setSelectedLang(locales["ru"]);
                setURL("ru")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ch') {
                setLang("Chinese")
                i18n.changeLanguage("ch");
                setSelectedLang(locales["ch"]);
                setURL("ch")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'pr') {
                setLang("Portuguese")
                i18n.changeLanguage("pr");
                setSelectedLang(locales["pr"]);
                setURL("pr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'vt') {
                setLang("Vietnamese")
                i18n.changeLanguage("vt");
                setSelectedLang(locales["vt"]);
                setURL("vt")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
        }
    }, [])

    // Get the button:
    let mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            if (!isMobile)
                mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <nav className=" z-10 bg-[#111010] fixed  top-0  pb-3 sm:pb-0 xs:pb-0 w-full " >
                <button className="bgcolor md:hidden xs:hidden sm:hidden" style={{

                    display: "none",
                    position: "fixed",
                    bottom: "20px",
                    right: "30px",
                    zIndex: "99",
                    border: "none",
                    outline: "none",
                    background: "linear-gradient(90deg, #AE8625 -3.12%, #F7EF8A 29.71%, #D2AC47 73.6%, #EDC967 102.14%) !important",
                    backdropFilter: "blur(38.5864px) !important",
                    color: "black",
                    cursor: "pointer",
                    padding: "5px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    borderRadius: "10px",
                    fontSize: "28px"
                }} onClick={topFunction} id="myBtn" title="Go to top">▲</button>


                {/* <header class="py-[2px] w-full xs:pb-2 header xs:fixed xs:bottom-0 bgcolor text-black items-center md:block sm:block xs:block text-center">
                    <div className="alert-container place-content-center flex items-center md:block sm:block xs:block">
                        <div className="copy-container">
                            <h1 className="main-head text-[18px] font-bold ">{('$SCORP Listing on XT.com')}</h1>
                        </div>
                        <div className='flex items-center justify-center'>
                            <ul className="countdown-clock 2xl:grid xl:grid lg:grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:inline-flex sm:inline-flex xs:inline-flex">
                                <li className='px-2 xs:px-1 text-[10px]'><span id="days" className='font-bold text-[18px]'>{Math.floor(distance / (day))}</span> {("Days")}</li>
                                <li className='px-2 xs:px-1 text-[10px]'><span id="hours" className='font-bold text-[18px]'>{Math.floor((distance % (day)) / (hour))}</span> {("Hours")}</li>
                                <li className='px-2 xs:px-1 text-[10px]'><span id="minutes" className='font-bold text-[18px]'>{Math.floor((distance % (hour)) / (minute))}</span> {("Mins")}</li>
                                <li className='px-2 xs:px-1 text-[10px]'><span id="seconds" className='font-bold text-[18px]'>{Math.floor((distance % (minute)) / second)}</span> {("Secs")}</li>
                            </ul>
                            <div className="nav-button-container ">
                                <a className="nav-button-wrap">
                                    <div id="nav-button" className='border-2 text-[13px] ml-4 border-inherit rounded text-black px-2 uppercase' style={{
                                        borderColor: "black"
                                    }} ><a href="https://pancakeswap.finance/swap?outputCurrency=0xa910a46e2f2002fa9b5aa85f35b9440f6dac4b10">{('BUY $SCORP')}</a></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </header > */}
                <header className="flex justify-between  2xl:px-[90px] xl:px-[90px] lg:px-[90px] md:px-[90px] mt-2">
                    <div
                        className="flex items-center space-x-10 h-16 max-w-screen-2xl sm:px-0 xs:px-0 lg:px- "
                    >
                        <div className="flex items-center">
                            <button type="button" onClick={handleNavbarMenu} className="p-1 sm:mr-4 xl:hidden lg:hidden 2xl:hidden">
                                <svg
                                    className="w-6 h-6 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                        fill='white'
                                    />
                                </svg>
                            </button>
                            <div className="flex items-center w-[160px]">
                                <a href="#hero"  >
                                    <img src={Logo} className="h-[55px] xs:h-[52px] sm:h-[53px] w-[100%]" alt="Logo" />
                                </a>
                            </div>


                        </div>
                        <div className='flex items-center justify-between xs:justify-start space-x-5 xs:space-x-0 '>
                            <div className='flex justify-between space-x-6 xs:hidden sm:hidden md:hidden '>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#scorpion">{t('main.navlink1')}</a> </h4>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]'>  <a href="#howto">{t('main.navlink2')}</a></h4>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#whitepaper">{t('main.navlink3')}</a> </h4>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#token">{t('main.navlink4')}</a> </h4>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]' > <a href="#success">{t('main.navlink5')}</a> </h4>
                                <h4 className='text-white font-[Inter] text-[16px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]'> <a href="#faqs">{t('main.navlink6')}</a> </h4>

                            </div>

                            <li className='flex items-center xs:hidden sm:hidden md:hidden'>
                                <div className='relative '>

                                    <button onClick={handleLangDropdown} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center px-2"
                                        style={{
                                        }} type="button" >
                                        <img src={selectedLang.img} width={20} className="mr-2" />
                                        {selectedLang.title}

                                        <svg className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""}`} aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                                    {
                                        langStatus &&
                                        <>
                                            <div className='relative'>


                                                <div id="dropdown" ref={dropdownRef} className="absolute w-[1000px]   -left-[630%] top-[21px] z-10 ">
                                                    <HoverDropDn onLanguageChange={handleLanguageChange} locales={locales} />

                                                </div>
                                            </div>
                                            <div onClick={handleLangDropdown} className="relative">
                                                <div className="bg-[#f0f8ff00] !w-[1400px] -top-[2] right-[-513%]  absolute"></div>
                                            </div>
                                            {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                                        </>
                                    }

                                </div>

                            </li>

                        </div>
                    </div>
                    <div className='flex items-center '>

                        <ConnectButton.Custom>
                            {({
                                account,
                                chain,
                                openAccountModal,
                                openChainModal,
                                openConnectModal,
                                authenticationStatus,
                                mounted,
                            }) => {
                                // Note: If your app doesn't use authentication, you
                                // can remove all 'authenticationStatus' checks
                                const ready = mounted && authenticationStatus !== 'loading';
                                const connected =
                                    ready &&
                                    account &&
                                    chain &&
                                    (!authenticationStatus ||
                                        authenticationStatus === 'authenticated');

                                return (
                                    <div
                                        {...(!ready && {
                                            'aria-hidden': true,
                                            'style': {
                                                opacity: 0,
                                                pointerEvents: 'none',
                                                userSelect: 'none',
                                            },
                                        })}
                                    >
                                        {(() => {
                                            if (!connected) {
                                                return (

                                                    <button className='bgcolor 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[12px] font-[Inter] font-[700] rounded-full px-4 h-[40px]'
                                                        onClick={openConnectModal} > {t('main.navBtn')}</button>

                                                );
                                            }

                                            if (chain.unsupported) {
                                                return (
                                                    <button className='bgcolor 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[12px] font-[Inter] font-[700] rounded-full px-4 h-[40px]' onClick={openChainModal}>
                                                        Switch Network
                                                    </button>
                                                );
                                            }

                                            return (
                                                <div>
                                                    <button className='px-3  rounded-full items-center justify-start w-[165px] xs:w-[150px] lg:w-[150px] h-[38px] flex items-center  p-1.5 text-[13px] xs:text-[12px] font-semibold font-[poppins] text-white space-x-1 relative bg-[#3f3f3f]' onClick={() => setOpen(!open)}>
                                                        <div className='absolute bg-white -right-[2.5px] h-[40px] w-[40px] rounded-full border border-[#3f3f3f] flex justify-center items-center'>
                                                            <span className='  '>
                                                                <img className='' src={WalletBlue} alt="" />
                                                            </span>
                                                        </div>
                                                        <span>
                                                            {/* {userWallet} */}
                                                            {account.address.slice(0, 6)}...{account.address.slice(-4)}
                                                        </span>

                                                    </button>
                                                    {
                                                        open ? <div className="z-10 absolute right-20 sm:-right-0 xs:-right-0 top-[110px] xs:top-[80px]  sm:w-full xs:w-full w-[23%] lg:w-[23%] rounded-[1rem] bg-[#000000] h-[600px] block overflow-y-auto"  >
                                                            <MyWallet accounts={account.address} />
                                                        </div> :
                                                            <></>
                                                    }
                                                </div>
                                            );
                                        })()}
                                    </div>
                                );
                            }}
                        </ConnectButton.Custom>
                    </div>
                </header >
                {
                    navBarOpen &&
                    <div className='' >

                        <div className=' relative  space-y-8 py-12 px-6 2xl:hidden xl:hidden lg:hidden h-[1800px] bg-[#111010] w-full left-0 absolute'>

                            <button className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}  ><a href="#scorpion">{t('main.navlink1')}</a></button>
                            <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} ><a href="#howto">{t('main.navlink2')}</a></h4>
                            <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} > <a href="#whitepaper">{t('main.navlink3')}</a> </h4>
                            <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} > <a href="#token">{t('main.navlink4')}</a></h4>   <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose} >  <a href="#success">{t('main.navlink5')}</a></h4>
                            <h4 className='text-white font-[Inter] text-[16px] font-[400] hover:text-[#a0a0a0]' onClick={handleNavbarMenuClose}><a href="#faqs">{t('main.navlink6')}</a></h4>
                            <li className='flex items-center '>
                                <div className='relative '>

                                    <button onClick={handleLangDropdown} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center "
                                        style={{
                                        }} type="button" >
                                        <img src={selectedLang.img} width={20} className="mr-2" />
                                        {selectedLang.title}
                                        <svg className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""}`} aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

                                    {
                                        langStatus &&
                                        <>
                                            <div className='relative'>


                                                <div id="dropdown" ref={dropdownRef} className="absolute xs:w-[200px] xs:mx-auto w-[1000px] x   xs:left-[10%] -left-[860%] top-[3px] z-10 ">
                                                    <HoverDropDn onLanguageChange={handleLanguageChange} locales={locales} />

                                                </div>
                                            </div>
                                            <div onClick={handleLangDropdown} className="relative">
                                                <div className="bg-[#f0f8ff00] w-[1400px]  -top-[2] right-[-513%]  absolute"></div>
                                            </div>
                                            {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                                        </>
                                    }

                                </div>

                            </li>





                        </div>
                    </div>
                }


            </nav >
        </>
    )
}
export default Navbar;
