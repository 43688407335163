import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BigInt } from 'global';

import { toast } from "react-toastify";
import { useWriteContract, useAccount } from 'wagmi'
import {
    useDisconnect,
    useReadContract,
    useReadContracts,
} from 'wagmi'

import cross from "../../assects/images/cross.png"
import Bronze from "../../assects/images/bronze.png"
import Silver from "../../assects/images/silver.png"
import Gold from "../../assects/images/gold.png"
import benefits from "../../assects/icons/nfticon.png"
import Platinum from "../../assects/images/platinum.png"
import Crown from "../../assects/images/crown.png"
import c1 from "../../assects/images/c1.png"
import c2 from "../../assects/images/c2.png"
import c3 from "../../assects/images/c3.png"
import c4 from "../../assects/images/c4.png"
import c5 from "../../assects/images/c5.png"
import dd from "../../assects/images/dd.png"
import calculatedTotals from './data/CalculatedTotals.json';  // Adjust the path as necessary

// import { arrayAddress} from './data/address';  // Adjust the path as necessary
// import { amounts} from './data/amount';  // Adjust the path as necessary
// import { prices } from './data/price';  // Adjust the path as necessary
// import { codes } from './data/code';  // Adjust the path as necessary
// import { coupons } from './data/coupons';  // Adjust the path as necessary


import { bsc } from 'wagmi/chains'
const casinoProfits = [
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275, 0.00275,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
]
const casinoProfits2 = [

    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875, 0.000875,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
    0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437, 0.000437,
]
const stakeAndClaimContract = "0x255b82eC452E00e3E9D2c56441AB133B616704F8";
const scorpAddress = "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10";


const whenClaimData = {
    address: stakeAndClaimContract,
    abi: [{
        "inputs": [
            {
                "internalType": "address",
                "name": "addrs",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_stakeId",
                "type": "uint256"
            }
        ],
        "name": "whenClaim",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }],
}


const stakedAmountsData = {
    address: stakeAndClaimContract,
    abi: [{
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "stakedAmounts",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }]
}
const stakedTimestampsData = {
    address: stakeAndClaimContract,
    abi: [{
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "stakedTimestamps",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }]
}
const MyWallet = ({ accounts }) => {
    const { t, i18n } = useTranslation()
    const { chain } = useAccount()


    const [firstClaim, setFirstClaim] = useState("No stake")
    const [claimStake, setClaimStake] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [timeStamp, setTimestamp] = useState([]);
    const [timeStampDate, setTimestampDate] = useState([]);
    const [stakeIds, setStakeIds] = useState([]);
    const [totalStaked, setTotalStaked] = useState(0)
    const [finalUserTokens, setFinalUserTokens] = useState(0)
    const [finalBonusTokens, setFinalBonusTokens] = useState(0)
    const [finalUserContribution, setFinalUserContribution] = useState(0)
    const [claimDaysBNB, setClaimDaysBNB] = useState(0)
    const [claimDaysETH, setClaimDaysETH] = useState(0)

    const { data: userContribution, status: userContriStatus } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserContribiton",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserContribiton',
        chainId: 56,
        args: [accounts],
    })

    const { data: getClaimTokensData, writeContract: getClaimTokens, error: getClaimTokensError,
        isPending } = useWriteContract()
    const { data: getClaimData, writeContract: getClaim, error: getClaimError } = useWriteContract()


    const { data: userClaimed } = useReadContract({
        address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userClaimedUSDT",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'userClaimedUSDT',
        chainId: 56,
        args: [accounts],
    })

    const { data: userClaimedETH, error: userClaimedETHerror, status: userClaimedETHstatus, refetch: userClaimedETHRefetch } = useReadContract({
        address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userClaimedUSDT",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'userClaimedUSDT',
        chainId: 1,
        args: [accounts],
    })

    const { data: getUSDTData, writeContract: getUSDT } = useWriteContract()

    const { data: getUSDTDataETH, writeContract: getUSDTETH } = useWriteContract()


    const { data: getStakeIdData, status: getStakeIdStatus } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_staker",
                    "type": "address"
                }
            ],
            "name": "getStakeIds",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getStakeIds',
        chainId: 56,
        args: [accounts],
    })

    const { data: stakeUserAmountsData, status: stakeUserAmountsStatus } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "stakeUserAmounts",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'stakeUserAmounts',
        chainId: 56,
        args: [accounts],
    })

    // const { data: cdaysETH } = useReadContract({
    //     address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
    //     abi: [
    //         {
    //             "inputs": [
    //                 {
    //                     "internalType": "address",
    //                     "name": "",
    //                     "type": "address"
    //                 }
    //             ],
    //             "name": "userClaimTimes",
    //             "outputs": [
    //                 {
    //                     "internalType": "uint256",
    //                     "name": "",
    //                     "type": "uint256"
    //                 }
    //             ],
    //             "stateMutability": "view",
    //             "type": "function"
    //         }],
    //     functionName: 'userClaimTimes',
    //     chainId: 1,
    //     args: [accounts],
    // })
    const handleUSDT = () => {
        if (chain?.id === 56) {
            getUSDT({
                address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
                abi: [{
                    "inputs": [],
                    "name": "getUSDT",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }],
                functionName: 'getUSDT',
                chainId: bsc.id
            })
        }
        if (chain?.id === 1) {
            getUSDTETH({
                address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
                abi: [{
                    "inputs": [],
                    "name": "getUSDT",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }],
                functionName: 'getUSDT',
                chainId: 1
            })
        }
    }
    const handleClaim = () => {
        toast.info("Approve the transaction in your wallet.")

        if (chain?.id === 56) {
            getClaimTokens({
                address: stakeAndClaimContract,
                abi: [{
                    "inputs": [],
                    "name": "claimTokens",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }],
                functionName: 'claimTokens',
                chainId: bsc.id
            })
        }
    }
    const handleClaimStaked = () => {
        toast.info("Approve the transaction in your wallet.")

        if (chain?.id === 56) {
            if (claimable)
                getClaim({
                    address: stakeAndClaimContract,
                    abi: [{
                        "inputs": [
                            {
                                "internalType": "uint256",
                                "name": "_stakeId",
                                "type": "uint256"
                            }
                        ],
                        "name": "claim",
                        "outputs": [],
                        "stateMutability": "nonpayable",
                        "type": "function"
                    }],
                    functionName: 'claim',
                    chainId: bsc.id,
                    args: [stakeIds[claimable[0]]],
                })
        }
    }

    const [totalProfit, setTotalProfit] = useState(0);
    const [usdtProfit, setUsdtProfit] = useState(0);
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const disconnect = useDisconnect({
        onSuccess(data) {
            console.log('Success', data)
        },
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        onError(error) {
            console.log('Error', error)
        },
        onMutate(args) {
            console.log('Mutate', args)
        },

    })

    const [nft, setNFT] = useState(0)
    const [nftData, setNFTData] = useState("No NFT")
    const [nextNFT, setNextNFT] = useState("Bronze")
    const [nextNftImage, setNextNftImage] = useState(c1)
    const [nextNftGoal, setNextNftGoal] = useState(1000)
    const [nftImage, setNFTImage] = useState(benefits)

    function truncateToDecimals(num, dec = 2) {
        const calcDec = 10 ** dec
        return Math.trunc(num * calcDec) / calcDec;
    }

    const countDaysFromClaimBNB = (c) => {
        if (c !== 0) {
            var date = new Date(c * 1000);
            var resultFormat = date.toISOString().split('T')[0]
            const today = new Date();
            today.setHours(0, 0, 0, 0)
            const [year, month, day] = (resultFormat).split('-').map(Number);
            const launchDate = new Date(year, month - 1, day)
            const diff = today - launchDate;
            const res = Math.floor(diff / (1000 * 60 * 60 * 24));
            setClaimDaysBNB(res)
        }
        else
            setClaimDaysBNB(30)
    }
    const countDaysFromClaimETH = (c) => {
        if (c !== 0) {
            var date = new Date(c * 1000);
            var resultFormat = date.toISOString().split('T')[0]
            const today = new Date();
            today.setHours(0, 0, 0, 0)
            const [year, month, day] = (resultFormat).split('-').map(Number);
            const launchDate = new Date(year, month - 1, day)
            const diff = today - launchDate;
            const res = Math.floor(diff / (1000 * 60 * 60 * 24));
            setClaimDaysETH(res)
        }
        else
            setClaimDaysETH(30)

    }
    const countDays = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        const [year, month, day] = ("2023-07-10").split('-').map(Number);
        const launchDate = new Date(year, month - 1, day)
        const diff = today - launchDate;
        const res = Math.floor(diff / (1000 * 60 * 60 * 24));
        let y = 0;
        for (let x = 0; x < res; x++) {
            y += casinoProfits[x]
        }
        if (y > 0)
            setTotalProfit(y)
    }

    const countDays2 = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        const [year, month, day] = ("2023-11-19").split('-').map(Number);
        const launchDate = new Date(year, month - 1, day)
        const diff = today - launchDate;
        const res = Math.floor(diff / (1000 * 60 * 60 * 24));
        let y = 0;
        for (let x = 0; x < res; x++) {
            y += casinoProfits2[x]
        }
        setUsdtProfit(y)
    }

    const { data: userTokens, isSuccess: successUserTokens } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 56,
        args: [accounts],
    })


    const { data: userBonus, isSuccess: successUserBonusTokens } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserBonusTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserBonusTokens',
        chainId: 56,
        args: [accounts],
    })




    const stakingRewards = {
        30: 2622,       // 0.2622%
        90: 11799,      // 1.1799%
        180: 39330,     // 3.933%
        365: 157320,    // 15.732%
        730: 377568,    // 37.7568%
        1095: 707940    // 70.794%
    }

    // Convert seconds to days

    // Calculate reward percentages based on the mapped days to rewards
    function convertSecondsToDate(seconds) {
        // Convert seconds to milliseconds
        const milliseconds = seconds * 1000;

        // Create a new Date object with the milliseconds
        const date = new Date(milliseconds);

        setFirstClaim(date.toDateString());

        return date.toDateString();
    }
    // Calculate reward percentages based on the mapped days to rewards
    function convertSecondsToDate2(seconds) {
        console.log(seconds)
        // Convert seconds to milliseconds
        const milliseconds = seconds * 1000;

        // Create a new Date object with the milliseconds
        const date = new Date(milliseconds);


        return date.toDateString();
    }

    const [numb, setNumb] = useState([])


    const { data: whenClaimLoop } = useReadContracts({

        contracts: stakeIds.map((item) => ({
            ...whenClaimData,
            functionName: 'whenClaim',
            args: [accounts, item],
        }
        ))
    })

    const { data: stakeAmountLoop } = useReadContracts({

        contracts: stakeIds.map((item) => ({
            ...stakedAmountsData,
            functionName: 'stakedAmounts',
            args: [accounts, item]
        }
        ))
    })

    const { data: stakedTimestampsLoop } = useReadContracts({

        contracts: stakeIds.map((item) => ({
            ...stakedTimestampsData,
            functionName: 'stakedTimestamps',
            args: [accounts, item]
        }
        ))
    })

    useEffect(() => {

        const fetchStakeSeconds = async () => {
            const whenClaimArray = [];
            const timestampArray = [];
            const stakedAmountArray = []
            if (whenClaimLoop && stakedTimestampsLoop && stakeAmountLoop) {
                for (let id of whenClaimLoop) {
                    const result = await id.result;
                    whenClaimArray.push(Number(result)); // Convert BigNumber to JavaScript number if necessary
                }
                for (let id of stakedTimestampsLoop) {
                    const result = await id.result;
                    timestampArray.push(Number(result)); // Convert BigNumber to JavaScript number if necessary
                }
                for (let id of stakeAmountLoop) {
                    const result = await id.result;
                    stakedAmountArray.push(Number(result) / 1e18); // Convert BigNumber to JavaScript number if necessary
                }
            }
            console.log(whenClaimArray)
            console.log(timestampArray)
            if (whenClaimArray)
                convertSecondsToDate(Number(whenClaimArray[0]))
            // console.log(whenClaimArray)
            // console.log(timestampArray)
            // console.log(stakedAmountArray)
            // for (let id of stakeIds) {
            //     setClaimStake(id)
            //     const number = whenClaimData; // Assuming getNumbers takes an ID and returns a number
            //     const number2 = stakedTimestampsData; // Assuming getNumbers takes an ID and returns a number
            //     timestampArray.push(Number(number2)); // Convert BigNumber to JavaScript number if necessary
            //     const number3 = stakedAmountsData; // Assuming getNumbers takes an ID and returns a number
            //     stakedAmountArray.push(Number(number3) / 1e18); // Convert BigNumber to JavaScript number if necessary

            // }

            setTimestamp(whenClaimArray); // Update state with all fetched seconds
            setTimestampDate(whenClaimArray); // Update state with all fetched seconds
            const resultArray = [];

            for (let i = 0; i < whenClaimArray.length; i++) {
                const difference = whenClaimArray[i] - timestampArray[i];
                resultArray.push(Number(difference));
            }


            const days = resultArray.map(seconds => Math.round(seconds / 86400));

            const rewardPercentages = days.map(day => {
                if (day >= 1095) return stakingRewards[1095] / 1000000;
                else if (day >= 730) return stakingRewards[730] / 1000000;
                else if (day >= 365) return stakingRewards[365] / 1000000;
                else if (day >= 180) return stakingRewards[180] / 1000000;
                else if (day >= 90) return stakingRewards[90] / 1000000;
                else if (day >= 30) return stakingRewards[30] / 1000000;
                else return 0;
            });

            const toEarnArray = stakedAmountArray.map((amount, index) => rewardPercentages[index] * amount);


            const getDaysFromPast = (pastUnix) => {
                const now = new Date(); // Current date and time
                const past = new Date(pastUnix * 1000); // Convert UNIX timestamp (seconds) to JavaScript Date (milliseconds)
                const difference = now - past; // Difference in milliseconds between now and the past date
                return Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
            };

            // Calculate days since each timestamp in timestampArray
            const daysSinceArray = timestampArray.map(getDaysFromPast);
            // // Calculate the daily reward percentage
            const dailyRewardsPercentage = toEarnArray.map((totalReward, index) => {
                const dailyReward = totalReward / days[index]; // Daily reward amount
                return (dailyReward * daysSinceArray[index]); // Daily percentage increase
            });
            const totalEarnedStaked = dailyRewardsPercentage.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setTotalEarned(totalEarnedStaked)

            const totalStakedAmount = stakedAmountArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setTotalStaked(Number(totalStakedAmount))
            setNumb(numb => [...numb, totalEarnedStaked]);

            // // Determine the daily reward rate based on the 1095 day plan
            // const dailyRate = stakingRewards[1095] / 1000000 / 1095;

            // // Calculate total reward for the actual number of days since the timestamp
            // const rewards = daysSince.map((days, index) => {
            //     const dailyReward = stakedAmountArray[index] * dailyRate; // Daily reward amount
            //     return dailyReward * days; // Total reward for the days elapsed
            // });

            // console.log(`Days Since Timestamp: ${daysSinceArray}`);
            // console.log(`Rewards Based on Days Since: ${rewards}`);
        };
        if (whenClaimLoop && stakedTimestampsLoop && stakeAmountLoop)
            fetchStakeSeconds();
    }, [claimStake, whenClaimLoop, stakedTimestampsLoop, stakeAmountLoop]);

    useEffect(() => {
        try {
            if (getStakeIdStatus === "success") {
                console.log("getStakeIdData", getStakeIdData)
                console.log("getStakeIdData[0]", getStakeIdData[0])
                setStakeIds(getStakeIdData)
                setClaimStake(Number(getStakeIdData[0]))
            }
            else
                setClaimStake(0)
        } catch (error) {
            console.log(error)
        }

    }, [getStakeIdStatus])

    useEffect(() => {
    }, [getClaimTokensError])

    const [claimable, setClaimable] = useState([])
    useEffect(() => {

        let ar = [];
        let arId = [];
        let x = 0;
        for (let id of timeStampDate) {
            let today = new Date().getTime() / 1000;
            if (id < today) {
                ar.push(id)
                arId.push(x)
            }

            x++;
        }
        setClaimable(arId)
    }, [stakeIds, timeStampDate])

    const [datass, setDatass] = useState(calculatedTotals);
    const [totalForUser, setTotalForUser] = useState(0);
    // Effect to load data
    // useEffect(() => {
    //     // Fetch the data from the local JSON file (adjust the path as necessary)
    //     fetch(calculatedTotals)
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log(data)
    //             setDatass(data)
    //         })
    //         .catch(error => console.error('Error loading the data:', error));
    // }, []);

    // Function to find total for the user's address
    const findTotal = () => {
        const adds = accounts.toLowerCase()
        const index = datass.addresses.indexOf(adds);
        if (index !== -1) {
            setTotalForUser(datass.totals[index]);
        } else {
            setTotalForUser(0);
        }
    };
    // function calculateTotalForAddress(address) {
    //     let total = 0;

    //     arrayAddress.forEach((currentAddress, index) => {
    //         if (currentAddress === address) {
    //             console.log(currentAddress)
    //             const amount = Number(amounts[index]);
    //             const price = Number(prices[index]);
    //             const code = codes[index];

    //             // Perform calculations for amount and price
    //             let calculatedValue = (amount / 1e6) * (price / 1e18);

    //             // Check for coupon discount
    //             if (coupons.includes(code)) {
    //                 const discountPercentage = parseInt(code.substring(2), 10);
    //                 calculatedValue += (calculatedValue * discountPercentage / 100);
    //             }

    //             // Add the calculated value to the total for the address
    //             total += calculatedValue;
    //         }
    //     });

    //     return total.toFixed(2); // Format the total to two decimal places
    // }

    useEffect(() => {
        if (userContriStatus === "success") {
            // console.log(calculateTotalForAddress(accounts))
            countDays()
            countDays2()
            let claimBNB = 1700496000
            let claimETH = 1700496000
            if (claimBNB)
                countDaysFromClaimBNB(claimBNB)
            if (claimETH)
                countDaysFromClaimETH(claimETH)
            setFinalUserTokens(Number(userTokens) / 1e18)
            findTotal()

            setFinalBonusTokens(Number(userBonus) / 1e18)
            setFinalUserContribution(Number(userContribution) / 1e6)
        }
    }, [userContribution])

    useEffect(() => {
        if (finalUserContribution >= 1000) {
            setNFT(0.1)
            setNFTData("Bronze")
            setNFTImage(Bronze)
            setNextNFT("Silver")
            setNextNftGoal(5000)
            setNextNftImage(c2)
        }
        if (finalUserContribution >= 5000) {
            setNFT(0.15)
            setNFTData("Silver")
            setNFTImage(Silver)
            setNextNFT("Gold")
            setNextNftGoal(10000)
            setNextNftImage(c3)
        }
        if (finalUserContribution >= 10000) {
            setNFT(0.25)
            setNFTData("Gold")
            setNFTImage(Gold)
            setNextNFT("Platinum")
            setNextNftGoal(25000)
            setNextNftImage(c4)
        }
        if (finalUserContribution >= 25000) {
            setNFT(0.4)
            setNFTData("Platinum")
            setNFTImage(Platinum)
            setNextNftGoal(100000)
            setNextNFT("Diamond")
            setNextNftImage(c5)
        }
        if (finalUserContribution >= 100000) {
            setNFT(0.6)
            setNFTData("Diamond")
            setNFTImage(Crown)
        }

    }, [finalUserContribution])

    const [usdtMenu, setUsdtMenu] = useState(false)
    const [stakingMenu, setStakingMenu] = useState(false)
    const handleClose = () => {
        setUsdtMenu(!usdtMenu)
    }
    const handleStakingClose = () => {
        setStakingMenu(!stakingMenu)
    }
    return (
        <>

            <div
                style={{
                    borderRadius: "10px",
                    display: "block"
                }}>
                <div
                    style={{
                        height: "90px",
                        display: "block",
                        border: "4px",
                        borderStyle: "solid",
                        borderColor: "#f9d548",
                        borderRadius: "10px",
                    }}>
                    <div style={{
                        fontWeight: "bold",
                        lineHeight: "2",
                        fontSize: "15px",
                        color: "#fff",
                        border: "2px",
                        borderRadius: "10px",
                        padding: "10px 20px 20px 20px",
                        textAlign: "center"
                    }}>
                        {("Launch Price")}
                        <div>
                            1 SCORP = $0.05
                        </div>
                    </div>
                </div>
                <div style={{
                    fontWeight: "bold",
                    lineHeight: "2",
                    fontSize: "18px",
                    border: "4px",
                    borderStyle: "solid",
                    borderColor: "#f9d548",
                    borderRadius: "10px",
                    padding: "20px",
                    marginTop: "10px",
                    textAlign: "center",
                    height: "100%",
                    // height: "480px",
                    backgroundColor: "black",
                    color: "white"
                }}>
                    {`${month[new Date().getMonth()]} ${new Date().getDate()} ,  ${new Date().getFullYear()}`}
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/logo.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>SCORP</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{finalUserTokens.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span>
                        </div>
                    </div>
                    {/* {((finalUserTokens + finalBonusTokens) * (totalProfit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}

                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/casino.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>Bonus</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{(finalBonusTokens + (finalUserTokens * nft)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span>
                        </div>
                    </div>

                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/golddollar.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>{("Staking Rewards")}</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{totalForUser ? (totalForUser * (totalProfit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</span>
                            {/* 0.875% / Day in SCORP  */}
                        </div>
                    </div>
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }} onClick={handleStakingClose}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/golddollar.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>{("Staking Pool")}</span>
                                <img src={dd} alt='logo' width="20px" height="20px" style={{ marginRight: "6px", padding: "3px" }} />
                            </div>
                            <span style={{ justifySelf: "right" }}>{(totalStaked).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            {/* 0.875% / Day in SCORP  */}
                        </div>
                    </div>



                    {
                        stakingMenu &&
                        <div>
                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Staked")}</span>
                                    </div>

                                    <span style={{ justifySelf: "right" }}>{(totalStaked.toFixed(3)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>

                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Earned")}</span>
                                    </div>
                                    {numb.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(3)}
                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Total")}</span>
                                    </div>
                                    {(totalStaked + numb.reduce((accumulator, currentValue) => accumulator + currentValue, 0)).toFixed(3)}
                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Lock Up Period")}</span>
                                    </div>
                                    {firstClaim}
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ display: "grid", fontSize: "13px", marginTop: "10px" }} onClick={handleClose}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/USDT.png" alt='logo' width="30px" height="30px" style={{ marginRight: "6px", padding: "3px" }} />

                                <span style={{ justifySelf: "center" }}>{("USDT Rewards")}</span>
                                <img src={dd} alt='logo' width="20px" height="20px" style={{ marginRight: "6px", padding: "3px" }} />
                            </div>

                            {

                                finalUserContribution < 10000 &&
                                0


                            }
                            {
                                finalUserContribution >= 10000 && finalUserContribution <= 24999 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }

                            {
                                finalUserContribution >= 25000 && finalUserContribution <= 99999 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .15 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }

                            {
                                finalUserContribution >= 100000 &&
                                <span style={{ justifySelf: "right" }}>{((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .2 * .05)).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                </span>
                            }
                        </div>
                    </div>


                    {
                        usdtMenu &&
                        <div>
                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Claimed")}</span>
                                    </div>


                                    <span style={{ justifySelf: "right" }}>{(Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>

                                </div>
                            </div>

                            <div style={{ display: "grid", fontSize: "10px", marginTop: "5px", marginLeft: "35px" }}>
                                <div style={{ display: "flex", alignItems: "center", fontSize: "10px", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <span style={{ justifySelf: "center" }}>{("Staked")}</span>
                                    </div>

                                    {

                                        finalUserContribution < 10000 &&
                                        0
                                        // <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05)) - Number(userClaimed)/1e18).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        // </span>


                                    }
                                    {
                                        finalUserContribution >= 10000 && finalUserContribution <= 24999 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .1 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}                                        </span>
                                    }

                                    {
                                        finalUserContribution >= 25000 && finalUserContribution <= 99999 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .15 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        </span>
                                    }

                                    {
                                        finalUserContribution >= 100000 &&
                                        <span style={{ justifySelf: "right" }}>{(((((finalUserTokens + finalBonusTokens) * (Number(usdtProfit))) * .2 * .05) - (Number(userClaimed) / 1e18 + Number(userClaimedETH) / 1e6))).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div style={{ display: "grid", fontSize: "13px", marginTop: "13px" }}>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={nftImage} alt='logo' width="30px" height="30px" style={{ marginRight: "6px" }} />

                                <span style={{ justifySelf: "center" }}>Scorpion NFT</span>
                            </div>
                            <span style={{ justifySelf: "right" }}>{nftData} </span>
                        </div>
                    </div>


                    <p className="text-[12px] mt-5 text-red-500 font-bold">
                        Approve Transaction in your wallet.
                    </p>

                    {
                        finalUserContribution < 10000 ?
                            <div>
                                <button
                                    className="bg-[#919191] w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                    // type="button" onClick={handleUSDT}
                                    type="button" onClick={() => toast.error("You must have at least Gold Membership NFT to receive USDT rewards.")}
                                >
                                    {("Withdraw USDT")}
                                </button>
                            </div>
                            :

                            <div>


                                {claimDaysBNB > 29 ?

                                    <button
                                        className="bgcolor w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                        type="button" onClick={handleUSDT}

                                    // type="button" onClick={() => toast.error("You can withdraw USDT after 30 days.")}
                                    >

                                        {("Withdraw USDT")}
                                    </button> :
                                    <>
                                        {
                                            claimDaysETH > 29 ?

                                                <button
                                                    className="bgcolor w-[100%] py-1  mt-6 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                                    type="button" onClick={handleUSDT}
                                                // type="button" onClick={() => toast.error("You can withdraw USDT after 30 days.")}

                                                >
                                                    {("Withdraw USDT")}
                                                </button>
                                                : <>
                                                </>
                                        }
                                    </>
                                }



                            </div>
                    }{
                        claimable.length > 0 ?
                            <div>
                                <button
                                    className="bgcolor w-[100%] py-1  mt-2   text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                    type="button"
                                    onClick={handleClaimStaked} >
                                    {("Claim Staked $SCORP")}
                                </button>
                            </div>
                            :

                            <div>
                                <button
                                    className="bg-[#919191] w-[100%] py-1  mt-2   text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                                    type="button"
                                    onClick={handleClaimStaked} >
                                    {("Claim Staked $SCORP")}
                                </button>
                            </div>
                    }
                    <div>
                        <button
                            className="bgcolor w-[100%] py-1  mt-2   text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button"
                            onClick={handleClaim} >
                            {("Claim $SCORP")}
                        </button>
                    </div>

                    <div>
                        <button
                            className="bgcolor w-[100%] py-1 mt-2 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button"
                            onClick={() => disconnect.disconnect()}>
                            {("Disconnect")}
                        </button>
                    </div>

                    {/* <div>
                        <button
                            className="bgcolor w-[100%] py-1 mt-2 text-[15px] rounded xs:text-[14px] font-[600] font-[Poppins] text-black"
                            type="button"
                            onClick={handleClaim}>
                            {("Claim Test")}
                        </button>
                    </div> */}

                    <p className="text-[12px] mt-5 text-red-500 font-bold">
                        How to Claim $SCORP?
                    </p>
                    <p className="text-[10px]">
                        Follow this step-by-step guide to instantly claim your $SCORP without encountering any issues. <a href="https://twitter.com/ScorpionCasino/status/1780528779191796017" className="underline font-bold">Click here.</a>
                    </p>
                </div>

            </div>

        </>
    )
}

export default MyWallet